<template>
    <right-list locale="en" />
</template>

<script>
import RightList from '@/components/RightList'

export default {
    name: 'En',
    components: { RightList }
}
</script>